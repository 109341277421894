export default defineNuxtPlugin({
  name: 'locale-detect',
  parallel: true,
  async setup(nuxtApp) {
    const { origin } = useRequestURL();
    const {
      public: { clientUrls },
    } = useRuntimeConfig();

    if (origin.replace(/\/\/www./, '//') === clientUrls.en) {
      await nuxtApp.$i18n.setLocale('en');
    }
  },
});
